import React, { useState, useEffect, createContext, useContext } from "react"
import PropTypes from "prop-types"

import MenuHeader from "./MenuHeader"
import FooterPage from "./FooterPage"
import IconChat from "images/icon_messenger_chat.svg"
import IconCall from "images/icon_call_homepage.svg"
import IconGift from "images/Home/Layout/gift-popup-icon.png"
import GiftModal from "./GiftModal"
import "./Layout.scss"

const AppContext = createContext({})

const Layout = ({ children, displayApplyBtn = false }) => {
  const [scrollBar, setScrollBar] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [isShowGiftModal, setIsShowGiftModal] = useState(false)

  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 1024) // Define your mobile breakpoint
  }

  useEffect(() => {
    window.history.scrollRestoration = "manual"
  }, [])

  useEffect(() => {
    const onDomLoaded = () => {
      FB.XFBML.parse()
    }
    document.addEventListener("DOMContentLoaded", onDomLoaded)
    return () => document.removeEventListener("DOMContentLoaded", onDomLoaded)
  }, [])

  useEffect(() => {
    const onScroll = () => {
      const scrollTop = window.pageYOffset
      setScrollBar(scrollTop)
    }
    window.removeEventListener("scroll", onScroll)
    window.addEventListener("scroll", onScroll, { passive: true })
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    checkMobile()
    window.addEventListener("resize", checkMobile)

    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShowGiftModal(true)
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const hash = window.location.hash

    if (hash) {
      const hashTimer = setTimeout(() => {
        const targetElement = document.querySelector(hash)

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" })
        }
      }, 500)

      return () => clearTimeout(hashTimer)
    }
  }, [])

  return (
    <AppContext.Provider value={{ isMobile }}>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WSSBHZKD" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>
        `,
        }}
      />
      <div className={`ywedyxqmzp ${scrollBar > 0 ? "swcwhskqnx" : ""}`}>
        <MenuHeader />
      </div>
      <main>{children}</main>
      <FooterPage />
      <button className="float-gift" onClick={() => setIsShowGiftModal(true)}>
        <img src={IconGift} className="my-float" alt="" />
      </button>
      <a
        href="https://www.messenger.com/t/729834207196440/"
        target="_blank"
        className="float-msg"
      >
        <img src={IconChat} className="my-float" alt="" />
      </a>
      <a href="tel:+842873056686" className="float01">
        <img src={IconCall} className="my-float" alt="" />
      </a>

      {displayApplyBtn && (
        <button
          className="float02 layout-apply-btn"
          onClick={() => {
            const url =
              "https://onpoint.jobday.vn/detail/XCCTQXGY/13265/OnPoint-TRAILBLAZERS---Management-Trainee-2024&apply=true"
            window.open(url, "_blank").focus()
          }}
        >
          APPLY
        </button>
      )}
      <GiftModal
        show={isShowGiftModal}
        onHide={() => setIsShowGiftModal(false)}
      />
    </AppContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAppContext = () => useContext(AppContext)

export default Layout
